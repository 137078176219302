<template>
  <div class="main">
    <h2>{{ $t('msg.settings.title') }}</h2>
    <p class="my-4">{{ $t('msg.settings.subtitle') }}</p>
    <div class="card p-3">
      <div class="row card-body">
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('general') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model>
            <j-input
              prop="name"
              v-model="name"
              icon="apple"
              read-only />
            <j-input
              prop="id"
              :label="$t('accountId')"
              v-model="id"
              icon="number"
              read-only />
            <j-input
              prop="email"
              v-model="email"
              icon="mail"
              read-only />
            <j-enum-select
              prop="printer"
              v-model="printer" />
          </a-form-model>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import lms from '@/api/lms'
import JInput from '@/views/shared/forms/input'
import JEnumSelect from '@/views/shared/forms/enum-select'
export default {
  components: {
    JInput,
    JEnumSelect,
  },
  data: function () {
    return {
      id: undefined,
      apiKey: undefined,
      name: undefined,
      email: undefined,
    }
  },
  mounted() {
    lms.account.fetchOne(this.account)
      .then(response => {
        this.id = response.data.id
        this.apiKey = response.data.api_key
        this.name = response.data.contact.name
        this.email = response.data.contact.email
      })
  },
  computed: {
    printer: {
      get () {
        return this.$store.state.settings.printer
      },
      set (printer) {
        this.$store.commit('SET_PRINTER', { printer })
      },
    },
  },
}
</script>
